<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          ref="compraMpRef"
          :fields="this.compra_mp.fields"
          :card="this.compra_mp.card"
          @change="compraMpChange"
        >
          <template v-slot:footer>
            <b-button
              variant="primary"
              class="mt-4 float-right"
              @click="addNewCompraMp"
              >Save</b-button
            >
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col> </b-col>
    </b-row>

    <ag-grid-table
      ref="grid-historico-freights"
      :configUrl="historicoFreightsConfig"
      :dataUrl="historicoFreightsData"
      :editUrl="historicoFreightsEdit"
    ></ag-grid-table>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";



import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";



export default {
  data()
  {
    return {


      historicoFreightsConfig: useApiServices.historicoFreightsConfig,
      historicoFreightsData: useApiServices.historicoFreightsData,
      historicoFreightsEdit: useApiServices.historicoFreightsEdit,




      compra_mp: {
        card: {
          title: "Agrega nueva compra de MP",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Fecha de Recepción",
            type: "datepicker",
            id: 'fecha_de_recepcion',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Especie",
            type: "input",
            id: 'especie',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "N",
            type: "input",
            id: 'n',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Peso Promedio",
            type: "input",
            id: 'peso_promedio',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },








    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },


  created()
  {

    this.loadCountries()



  },

  methods: {


    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },

    compraMpChange(field)
    {
      console.log(field)



    },



    addNewCompraMp()
    {
      let data = this.compra_mp.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})

      console.log({ data })

      Promise.all([
        this.$refs.compraMpRef.validate(),
      ]).then(([compraMpValid]) =>
      {
        if (compraMpValid)
        {



          
        }
      });


    }


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

